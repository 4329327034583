@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");

*,
body {
  font-family: "Libre Franklin" !important;
}


/* @media screen and (min-width: 1px) {
  body {
    visibility: visible;
  }
}

@media print {
  body {
    visibility: hidden;
  }
} */

:root {
  --main-color: #9e0050;
  --disable-color: #9e004f8e;
  --cream-color: #eee9e3;
  --light-pink: #FFE9F4
}

.filter-search-bar-div {
  background-color: white;
  margin: 0 5%;
  border-radius: 13px;
  box-shadow: 0 4px 22px 0 #6f6f6f33;
}

.global-input {
  display: flex;
  align-items: center;
  border: 1px solid #e3e3e3 !important;
  border-radius: 12px;
  padding-left: 5%;
  height: 50px !important;
  width: 95%;
}

.social-icons-div {
  display: flex;
  gap: 5%
}

.social-icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  cursor: pointer;
  color: var(--main-color);
  border: 2px solid var(--main-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.social-icon-white {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  cursor: pointer;
  color: white;
  border: 2px solid white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  background-color: var(--main-color);
  color: white;
}

.social-icon-white:hover {
  background-color: white;
  color: var(--main-color);
}

.register-from-input {
  display: flex;
  align-items: center;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  padding-left: 5%;
  height: 50px !important;
  /* background-color: #f9f9f9; */
  width: 95%;
}

.from-textarea {
  display: flex;
  align-items: center;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  padding: 2% 5%;
  width: 95%;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-label {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 1%;
}

.header-btn {
  background-color: var(--main-color) !important;
  color: white !important;
  border-radius: 7px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  box-shadow: none !important;
  padding: 11px 20px !important;
  text-transform: capitalize !important;
}

.register-btn {
  background-color: var(--main-color) !important;
  color: white !important;
  border-radius: 7px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  box-shadow: none !important;
  padding: 11px 20px !important;
  text-transform: capitalize !important;
}

.register-btn-disable {
  background-color: var(--disable-color) !important;
  color: white !important;
  border-radius: 7px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  box-shadow: none !important;
  padding: 11px 20px !important;
  text-transform: capitalize !important;
}

.footer-btn {
  background-color: white !important;
  color: var(--main-color) !important;
  border-radius: 7px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  box-shadow: none !important;
  padding: 11px 20px !important;
  margin-top: 3% !important;
}

.global-btn-dark {
  background-color: var(--main-color) !important;
  color: white !important;
  border-radius: 9px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  box-shadow: none !important;
  width: 100% !important;
  text-transform: capitalize !important;
  padding: 10px 70px !important;
}

.global-btn-light {
  background-color: white !important;
  color: black !important;
  border-radius: 7px !important;
  box-shadow: none !important;
  width: 50% !important;
  text-transform: capitalize !important;
  padding: 8px 70px !important;
}

.hero-section {
  background-image: url("../public/images/about-bg.png");
  background-size: cover;
  /* background-position: center; */
}

.css-zza0ns-MuiBadge-badge {
  background-color: var(--main-color) !important;
}

.css-zghsfl-MuiBadge-badge {
  background-color: white !important;
  color: black !important;
}

.wrapper {
  height: 10px;
}


.global-select {
  display: flex;
  align-items: center;
  border: 1px solid gainsboro;
  border-radius: 7px;
  padding: 2% 5%;
  width: 280px;
  height: 44.5px !important;
  opacity: 100%;
  background-color: white;
}

.multi-select div {
  width: 98.5% !important;
  padding: 0.8% 2% !important;
  border-radius: 7px !important;
}

.blur-details-div {
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
}

.blur-photos {
  filter: blur(7px);
  pointer-events: none;
  user-select: none;
}

.gender-select div {
  width: 100% !important;
  display: flex;
  justify-content: right;
  text-align: right;
}

.css-13cymwt-control {
  display: flex;
  align-items: center;
  border: 1px solid #e3e3e3 !important;
  border-radius: 7px !important;
  width: 100% !important;
  height: 46px !important;
  /* background-color: #f9f9f9 !important; */
}

.p-dropdown-items {
  border-radius: 10px !important;
  border: 1px solid gainsboro !important;
}

.p-dropdown-item {
  background-color: white !important;
  border-bottom: 1px solid #e3e3e3 !important;
  padding: 4% !important;
}

.p-dropdown-panel {
  z-index: 9999 !important;
}

.global-h2 {
  color: var(--main-color);
  font-weight: 500;
  font-size: 38px;
}

.global-h3 {
  color: var(--main-color);
  font-weight: 500;
  font-size: 30px;
}

.features-section {
  padding: 4% 0;
}

.tabs-section {
  width: 100%;
  padding: 5% 12%;
}

.user-profile-section {
  width: 100%;
  padding: 0;
}

.features-div-title {
  margin: 6% 0 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
}

.features-div-para {
  font-weight: 400;
  color: #8a8a8a;
  font-size: 14px;
  line-height: 19.39px;
}

.process-section {
  padding: 4% 15%;
  background-color: var(--cream-color);
}

.process-div {
  display: flex;
  padding: 5% 0;
}

.process-div-title {
  margin: 6% 0 3%;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
}

.process-div-para {
  font-weight: 400;
  color: #8a8a8a;
  font-size: 16px;
  line-height: 19.39px;
  width: 85%;
}

.process-border-dark {
  border-right: 4px solid var(--main-color);
}

.process-border-gray {
  border-right: 4px solid #6f6f6f33;
}

.main-header {
  display: flex;
  align-items: center;
  height: 80px;
  margin: 0;
  background-color: white;
}

.app-section {
  padding: 5% 6%;
}

.happiness-div-title {
  color: white;
  font-weight: 500;
  font-size: 33px;
  margin: 0;
  padding: 0;
}

.happiness-div-para {
  width: 55%;
  color: white;
  font-size: 15px;
  margin: 15px 0;
  line-height: 20.94px;
}

.happiness-images {
  margin-top: 3%;
}

.happiness-images img {
  width: 150px;
}

.footer-section {
  padding: 2% 7% 6%;
  background-color: white;
  background-image: url("../public/images/faq-bg-img.png");
  background-size: cover;
  color: black;
}

.faq-section {
  background-image: url("../public/images/faq-bg-img.png");
  background-size: cover;
  background-position: center;
}

.footer-div-title {
  width: 461px;
  height: 36px;
  top: 70px;
  left: 100px;
  color: #ffffff;
  font-size: 30px;
  margin-left: -4rem;
}

.footer-txt {
  font-size: 14px;
  font-weight: 400;
}

.get-started-button {
  width: 165px;
  height: 48px;
  border-radius: 10px;
  padding: 0px 38px;
  background: white;
  color: var(--main-color);
  margin-left: -4rem;
}

.tab-list-item-div {
  display: flex;
  justify-content: center;
  gap: 35px;
  flex-wrap: wrap;
}

.tab-list-item-div p {
  color: #212529;
}

.active-tabname {
  cursor: pointer;
  padding: 7px 18px;
}

.app-store-icon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 175.8px;
  height: 57.6px;
  object-fit: cover;
}

.play-store-icon {
  position: absolute;
  top: 68.7px;
  left: 0px;
  width: 175.8px;
  height: 57.6px;
  object-fit: cover;
}

.buttons {
  position: absolute;
  top: 59.7px;
  left: 0px;
  width: 175.8px;
  height: 126.3px;
}

.color-label {
  color: var(--main-color);
}

.checkout_section {
  position: relative;
  width: 100%;
  background-image: url("../public/images/lining-light-2.svg");
  background-size: cover;
  background-position: center;
}

.modal {
  position: relative;
  width: 100%;
  background-image: url("../public/images/lining-light.svg");
  background-size: cover;
  background-position: center;
}

.modal-v2 {
  position: relative;
  width: 100%;
  background-color: white;
  /* background: linear-gradient(to bottom, #fff9f1, white); */
}

.story-page {
  position: relative;
  width: 100%;
  background-image: url("../public/images/leaves-bg.png");
  background-size: cover;
  /* opacity: 50%; */
  background-position: center;
}

.profile-bg {
  position: relative;
  width: 100%;
  background-image: url("../public/images/profile-bg.svg");
  background-size: cover;
  background-position: center;
}

.otp-modal {
  position: relative;
  width: 100%;
  background-image: url("../public/images/lining-light-2.svg");
  background-size: cover;
  background-position: center;
}

.register-form {
  background-color: white;
  border-radius: 10px;
  padding: 6%;
  margin-top: 0%;
}

.otp-form {
  display: flex;
  flex-direction: row;
  gap: 3%;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  justify-content: center;
  margin: 13% 0;
}

.otp-form-txt {
  color: #616161;
  width: 90%;
  margin: auto;
  text-align: center;
  font-size: 14px;
}

.otp-form-inner-div {
  padding: 0 7%;
  margin: auto;
}

.modal-close-btn {
  position: absolute;
  right: 3%;
  top: 3%;
  height: 40px;
  width: 40px;
  opacity: 60%;
  cursor: pointer;
}

.modal-arrow-btn {
  position: absolute;
  left: 5%;
  top: 5%;
  height: 20px;
  width: 20px;
  opacity: 60%;
  cursor: pointer;
}

.invalid-input {
  border: 1px solid #DC3545;
}

.invalid-txt {
  color: #DC3545;
  font-weight: 400;
  font-size: 14px;
  margin: 0
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 12px !important;
  height: 52px !important;
  /* background-color: #f9f9f9 !important; */
}

.tab-items-div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #B4B4B4;
  margin-top: 3%;
}

.user-profile-tabs-div {
  display: flex;
  /* justify-content: space-between; */
}

.matching-features-section {
  position: relative;
  width: 100%;
  padding: 6% 10%;
  background-image: url("../public/images/about-bg.png");
  background-size: cover;
  background-position: center;
}

.about-bg {
  background-image: url("../public/images/about-bg.png");
  background-size: cover;
  background-position: center;
}

.pricing-div {
  position: relative;
  width: 100%;
  /* background-image: url("../public/images/dotted-img.svg"); */
  /* background-size: cover; */
  /* background-position: center; */
}

.accordians-parent-div {
  border: 1px solid #252525;
}

.accordian-div {
  padding: 2%;
  background-color: rgba(255, 255, 255, 0.789) !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.accordian-title {
  color: black;
  font-weight: 500;
  font-size: 23px;
}

.accordian-para {
  color: #808080;
  padding-left: 3%;
  font-weight: 400;
  font-size: 18px;
  width: 50%;
}

.add-icon {
  width: 40px !important;
  height: 40px !important;
  color: black !important;
}

.close-icon {
  width: 40px !important;
  height: 40px !important;
  color: black !important;
}

.circle-div-1 {
  height: 100%;
  width: 100%;
  background-color: #FF94CA;
  border-radius: 50%;
}

.circle-div-2 {
  height: 100%;
  width: 100%;
  background-color: #FFE087;
  border-radius: 50%;
}

.circle-div-3 {
  height: 100%;
  width: 100%;
  background-color: #A1CEE0;
  border-radius: 50%;
}

.circle-div-4 {
  height: 100%;
  width: 100%;
  background-color: #667dd7;
  border-radius: 50%;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root {
  border-bottom: 1px solid black;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.sign-login-page {
  height: 100vh;
}

.login-page-left-div {
  background-color: var(--main-color);
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page-right-div {
  background-color: var(--cream-color);
  height: 100%;
  position: relative;
  padding: 3% 10%;
  width: 100%;
  background-image: url("../public/images/lining-light.svg");
  background-size: cover;
  background-position: center;
}

.sidebar-div {
  overflow-y: auto;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.sidebar-div::-webkit-scrollbar {
  display: none !important;
}

.notifications-parent-div {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.notifications-parent-div::-webkit-scrollbar {
  display: none !important;
}

.content-section {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.content-section::-webkit-scrollbar {
  display: none !important;
}


.conversation-box::-webkit-scrollbar {
  width: 5px;
}

.conversation-box::-webkit-scrollbar-thumb {
  background-color: #b9b9b9;
  border-radius: 5px;
}

.conversation-box::-webkit-scrollbar-track:hover {
  background-color: #f0f0f0;
}

.conversation-box::-webkit-scrollbar-thumb:hover {
  background-color: #f0f0f0;
}

.notifications-div:hover {
  background-color: rgba(241, 241, 241, 0.326);
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.dashboard_content_grid {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dashboard_content_grid::-webkit-scrollbar {
  display: none;
}

.page-hero-section {
  padding: 7% 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.contact-form-section {
  padding: 7% 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.contact-info-div {
  background-color: #FAF6F5;
  border-radius: 10px;
  padding: 6%;
  position: relative;
}

.about-hero-section {
  padding: 7% 15% 0 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EAE5DF;
}

.about-company-section {
  padding: 7% 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-mission-section {
  padding: 2% 15% 7%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-para {
  color: #A3A3A3;
  font-size: 15px;
  font-weight: 400;
}

.plans_tenure input[type="radio"] {
  display: none;
}

.headline-label {
  color: var(--main-color);
  font-size: 18px;
  font-weight: 500;
}

.plans_tenure input[type="radio"]+label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  margin-right: 10px;
  position: relative;
  top: 4px;
}

.plans_tenure input[type="radio"]:checked+label::before {
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.plans_tenure label {
  cursor: pointer;
}




.dark-mode-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.toggle {
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: 25px;
  background-color: #ddd;
  /* Light mode background color */
  margin-right: 10px;
}

.toggle-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  transition: transform 0.3s ease;
}

.toggle-label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  /* Light mode text color */
}

.dark .toggle {
  background-color: #333;
  /* Dark mode background color */
}

.dark .toggle-thumb {
  background-color: #000;
  /* Dark mode toggle thumb color */
  transform: translateX(25px);
}

.dark .toggle-label {
  color: #fff;
  /* Dark mode text color */
}



.mode-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.toggle-button {
  padding: 10px 20px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.toggle-button.on {
  background-color: #4CAF50;
  color: white;
}

.toggle-button.off {
  background-color: #f44336;
  color: white;
}


.dropzone {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2px dotted var(--main-color);
  padding: 10% 5%;
  width: 100%;
  height: 150px;
  border-radius: 10px;
  opacity: 70%;
  font-weight: 500;
  background-color: var(--cream-color);
  justify-content: center;
}

.chat-contacts-div {
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.chat-contacts-div::-webkit-scrollbar {
  display: none;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #4CAF50 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: var(--main-color) !important;
}

.contact-form-right {
  padding: 2%;
}

.css-5xe99f-MuiLinearProgress-bar1 {
  background-color: #004cef !important;
}

.css-eglki6-MuiLinearProgress-root {
  background-color: rgb(229, 229, 229) !important;
}

.all-reviews-section {
  display: flex;
  width: 100%;
  align-items: center;
}

.review {
  border: 1px solid #ddd;
  /* background-color: white; */
  border-radius: 12px;
  padding: 20px;
  border: none;
  width: 100%;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.review-header {
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.review-info h2 {
  margin: 0;
  font-size: 1.2em;
}

.review-info p {
  margin: 5px 0;
}

.review-body {
  margin-top: 15px;
}

.rating {
  display: flex;
  align-items: center;
}

.star {
  font-size: 1.2em;
  color: #ddd;
}

.star.filled {
  color: #f5a623;
}

.testimonials-section {
  position: relative;
  /* background: linear-gradient(to bottom, var(--cream-color), white); */
}

.date {
  margin-left: 10px;
  font-size: 0.9em;
  color: #888;
}

.review-text {
  margin-top: 10px;
  opacity: 70%;
  font-size: 15px;
}

.review-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #012B65 !important;
  border-radius: 7px !important;
}

.container-new {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.fp-card {
  background: white;
  border-radius: 12px;
  border: 1px solid #F1F1F2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 480px;
  text-align: center;
}



.chatbot-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  max-width: 360px;
  width: 90%;
  z-index: 99;

  @media (max-width: 768px) {
    right: 10px;
    bottom: 10px;
  }
}

.chatbot-icon {
  position: absolute;
  bottom: 2%;
  left: 2%;
  background-color: var(--main-color);
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.chatbot-window {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  height: 500px;
  display: none;
}

.chatbot-window.open {
  display: flex;
}

.chatbot-header {
  display: flex;
  background-color: var(--main-color);
  padding: 5%;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px 15px 0 0;
}

.chatbot-header-below {
  display: flex;
  background-color: #7e0340;
  padding: 2% 5%;
  justify-content: space-between;
  align-items: center;
}

.chatbot-close-button {
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.chatbot-content {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  margin-top: 10px;
}

.chatbot-message {
  background-color: #f1f1f1;
  color: black;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

.chatbot-message.user {
  background-color: var(--main-color);
  color: white;
  align-self: flex-end;
}

.chatbot-input-container {
  display: flex;
  border-top: 1px solid #ddd;
  padding: 2%;
}


.super-admin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  background-color: var(--cream-color);
  box-sizing: border-box;
}

.sign-in-card {
  background: white;
  padding: 3%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 30%;
  margin: auto;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forgot-password {
  color: var(--main-color);
  text-decoration: none;
  font-size: 14px;
}

.forgot-password:hover {
  text-decoration: underline;
}

.sign-in-button {
  background-color: var(--main-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.sign-in-button:hover {
  background-color: var(--main-color);
}

.register-link {
  margin-top: 20px;
  font-size: 14px;
}

.register-link a {
  color: #007bff;
  text-decoration: none;
}

.register-link a:hover {
  text-decoration: underline;
}

.video-consultation {
  display: flex;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left-panel,
.right-panel {
  padding: 20px;
  cursor: pointer;
}

.left-panel {
  border-right: 1px dashed #e5e7eb;
}

.right-panel h2 {
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: #111827;
}

.meeting-card,
.appointment-card {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px dashed #e5e7eb;
  border-radius: 10px;
  /* text-align: center; */
  position: relative;
}

.meeting-card h2,
.appointment-card h2 {
  margin-bottom: 10px;
  font-size: 1.25rem;
  color: #111827;
}

.meeting-card p,
.appointment-card p {
  color: #6b7280;
  margin-top: 10px;
}

.card-image {
  transform: translate(-50%, -50%);
}

.appointment-card {
  background-color: #f0f9ff;
}

.appointment-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #F1F1F2;
  border-radius: 10px;
  background: white;
  padding: 1%;
  margin: 1% 0;
}

.appointment-info {
  display: flex;
  align-items: center;
}

.appointment-info img {
  border-radius: 50%;
  margin-right: 10px;
}

.appointment-info div {
  display: flex;
  flex-direction: column;
}

.appointment-info p {
  margin: 0;
}


/* Table and Mobile css */

@media only screen and (max-width: 768px) {

  .header-btn {
    border-radius: 7px !important;
    font-size: 14px !important;
    padding: 11px 70px !important;
  }

  .process-section {
    padding: 10% 4%;
  }

  .features-section {
    padding: 10% 1%;
  }

  .process-border-dark {
    border-right: none;
  }

  .process-border-gray {
    border-right: none;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
  }

  .global-h2 {
    font-size: 30px;
  }

  .mobile-top-div {
    width: 100%;
    display: flex;
    justify-content: center;
    order: 1 !important;
  }

  .mobile-bottom-div {
    order: 2 !important;
  }

  .process-div-para {
    width: 100%;
  }

  .process-images {
    width: 90%;
  }

  .process-2-image {
    width: 65%;
  }

  .tabs-section {
    width: auto;
    padding: 10% 0;
  }

  .app-section {
    padding: 10% 6%;
  }

  .happiness-div-title {
    font-size: 28px;
  }

  .happiness-div-para {
    width: 95%;
    margin: 15px 0;
  }

  .footer-section {
    padding: 4% 6%;
  }

  .tab-list-item-div {
    padding: 0 3%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .global-select {
    width: 100%;
  }

  .tab-items-div {
    display: flex;
    border-bottom: 1px solid #B4B4B4;
    margin-top: 8%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .tab-items-div::-webkit-scrollbar {
    display: none;
  }

  .tab-items-div {
    scrollbar-width: none;
  }

  .tab-items-div {
    -ms-overflow-style: none;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    width: 95% !important;
  }

  .matching-features-section {
    padding: 10% 4%;
  }

  .accordian-div {
    padding: 2%;
  }

  .accordian-title {
    font-size: 18px;
  }

  .accordian-para {
    padding-left: 3%;
    font-size: 15px;
    width: 100%;
  }

  .add-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .close-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .circle-div-1,
  .circle-div-2,
  .circle-div-3,
  .circle-div-4 {
    height: 100%;
    width: 100%;
  }

  .login-page-right-div {
    padding: 3%;
    width: 100%;
  }

  .otp-form-inner-div {
    padding: 7%;
  }

  .otp-form {
    margin: 10% 0;
  }

  .about-hero-section {
    padding: 7% 4% 0 4%;
  }

  .about-company-section {
    padding: 10% 4%;
  }

  .about-mission-section {
    padding: 0 4% 3%;
  }

  .page-hero-section {
    padding: 15%;
  }

  .contact-form-section {
    padding: 7% 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }

  .contact-info-div {
    background-color: var(--cream-color);
    border-radius: 10px;
    padding: 6%;
    position: relative;
  }

  .contact-form-right {
    margin-top: 5% !important;
    order: 1;
    padding: 5%;
  }

  .contact-form-left {
    margin-top: 10% !important;
    order: 2;
  }

  .review {
    padding: 15px;
  }

  .avatar {
    width: 40px;
    height: 40px;
  }

  .btn {
    padding: 8px 15px;
    font-size: 0.9em;
  }

  .fp-card {
    padding: 15px;
  }

  .sign-in-card {
    padding: 20px;
  }

  .left-panel {
    border-right: none;
    margin-bottom: 20px;
  }

  .right-panel {
    padding: 0 20px;
  }


}


@media (max-width: 480px) {
  .sign-in-card {
    padding: 15px;
  }

}


.appointment-form {
  /* max-width: 400px;
  margin: auto; */
  padding: 20px;
  /* border: 1px solid #ccc;
  border-radius: 8px; */
}

.form-group {
  margin-bottom: 20px;
}




.custom-multi-select .dropdown-container {
  background-color: white !important;
}

.dark-custom-multi-select .dropdown-container {
  background-color: #202B3E !important;
  border: none !important;
  color: rgb(112, 112, 112) !important;
}

.modal-dark-custom-multi-select .dropdown-container {
  background-color: #000C1F !important;
  border: none !important;
  color: rgb(112, 112, 112) !important;
}



.bio-data {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 40px;
  /* border: 3px solid var(--main-color); */
}

.bio-data h2 {
  color: var(--main-color);
  padding: 10px;
  padding-left: 0;
  font-weight: 600;
  margin-bottom: 10px;
}

.bio-data ul {
  list-style: none;
  padding: 0;
}

.bio-data ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.bio-data .key {
  font-weight: 600;
  margin-right: 10px;
}

.bio-data .value {
  flex-grow: 1;
  text-align: right;
}

.bio-data .image-section {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.bio-data .image-section img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.personal-details,
.family-details,
.contact-details {
  margin-bottom: 20px;
}


.whyUsBg {
  background-image: url("../public/images/why-us-bg.svg");
  background-size: cover;
  background-position: center;
}


.blurDiv {
  height: 600px;
  width: 600px;
  position: absolute;
  background: #FBE8EF;
  border-radius: 50%;
  filter: blur(150px);
  left: 30%
}